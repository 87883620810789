<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            <img
              src="@/assets/images/logo/logo-mercadohit.svg"
              width="150"
            >
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Criar nova senha 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          É hora de definir uma nova senha! Escolha uma combinação incrível que ninguém jamais poderia adivinhar.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- password -->
            <b-form-group
              label="Nova Senha"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirme Nova Senha"
            >
              <validation-provider
                #default="{ errors }"
                vid="cPassword"
                name="cPassword"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
            >
              Trocar Senha
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'autenticacao-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>

    <!-- basic modal -->
    <b-modal
      id="confirmacaoCarregando"
      ref="confirmacaoCarregando"
      centered
      title="Recuperação de Senha"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text class="text-center p-2">
        <b-spinner
          class="mt-2"
          variant="primary"
        /><br>
        <strong>Aguarde um pouquinho!</strong><br> Estamos alterando sua senha para garantir sua segurança. Fique tranquilo(a), já estamos quase lá!
      </b-card-text>
    </b-modal>


  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useJwt from '@/auth/jwt/useJwt'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      password: {
        required: 'A senha é obrigatória.',
      },
      cPassword: {
        required: 'Confirmação obrigatória.',
        confirmed: 'A senhas não estão iguais.',
      },
    },
  },
})


export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      respostaApi: false,
      token: null,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {

    if(this.$route.params.token) {

      this.token = this.$route.params.token

    }

  },
  methods: {
    
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          // MODAL - CONFIRMAÇÃO CARREGANDO - ABRIR
          this.$refs.confirmacaoCarregando.show()
          
          useJwt.usuarioNovaSenha({
            token: this.token,
            novaSenha: this.password
          })
          .then(response => {
            
            this.$swal({
              title: 'Senha Alterada com Sucesso!',
              text: 'Sua senha foi trocada com sucesso. Agora você pode fazer login em sua conta usando a nova senha. Lembre-se de mantê-la em segredo e aproveite a experiência!',
              icon: 'success',
              confirmButtonText: 'Ir para o login',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {

                this.$router.push({ name: 'autenticacao-login' })

              } else {

                this.$router.push({ name: 'autenticacao-login' })

              }
              // console.log( result );
            })
            // this.$router.push('/')
          })
          .catch(error => {
            //console.log( error.response );
            this.$swal({
              title: 'Erro!',
              text: error.response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          })
          .finally(() => {

            // MODAL - CONFIRMAÇÃO CARREGANDO - FECHAR
            this.$refs.confirmacaoCarregando.hide()

          })


          
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
